<template>
  <div class="privacy-policy-wrapper">
    <VueContentHolder id="divPrivacyPolicyWrapper" :padding="[25, 25, 100]">
      <div class="content" v-html="content"></div>
    </VueContentHolder>
  </div>
</template>
<script>
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'PrivacyPolicy',
  components: { VueContentHolder },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: null,
    };
  },
  methods: {
    getPrivacyPolicy() {
      const methodName = this.isPublic ? 'getPublicStaticContent' : 'getStaticContent';
      StaticContent[methodName]('VeriPolitikalari').then(res => {
        if (res.data?.Data?.content) {
          this.content = res.data.Data.content;
        }
      });
    },
  },
  created() {
    this.getPrivacyPolicy();
  },
};
</script>

<style scoped lang="scss">
.privacy-policy-wrapper {
  height: 100vh;
  overflow-y: scroll;
}
.content {
  line-height: 1.2;
}
</style>
