<template>
  <div>
    <VueAppbar
      :isPublicLayoutBar="true"
      :hasBackButton="true"
      :withBg="true"
      :isCollapsable="false"
      :isJustLogo="true"
    />
    <PrivacyPolicy :isPublic="true" />
  </div>
</template>
<script>
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar.vue';
import PrivacyPolicy from '@/views/pages/secure/settings/PrivacyPolicy.vue';

export default {
  name: 'OneTrustCookieSettings',
  components: {
    VueAppbar,
    PrivacyPolicy,
  },
};
</script>

<style lang="scss" scoped></style>
